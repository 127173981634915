<template>
  <div class="card">
    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="true"
          title="Incentive Payment Add"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="my-2 px-2">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
                for="colFormLabel"
                class="col-form-label"
            >
              Referrer
            </label>
            <AsyncSelect
                placeholder="Select Referrer"
                v-model="referrer"
                :api-service="fetchContactProfiles"
                :additional-query="{ type: 'agent' }"
                :format-label="formatPatientLabel"
                :reduce="referrer => referrer.id"
            />
          </div>
          <div class="col-sm-2 col-md-1 d-flex align-items-end mt-2 action-btn-setter">
            <button
                style="min-width: 64px;"
                @click="onClickToGo"
                class="btn btn-primary btn-block waves-effect waves-float waves-light"
            > GO
            </button>
          </div>
        </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="onClickToPrint"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Draft Statement Print
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-2 mt-1 mb-4">
      <div class="card p-1" style="min-height: 150px">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th class="text-center">Invoice Date</th>
              <th class="text-center">Invoice No</th>
              <th class="text-center">Patient Name (ID)</th>
              <th class="text-center">Total Invoice</th>
              <th class="text-center">Total Discount</th>
              <th class="text-center">Net Bill</th>
              <th class="text-center">Total Incentive</th>
              <th class="text-center">Referrer-Discount</th>
              <th class="text-center">Net Incentive</th>
              <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in tableItems" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.bill_number }}</td>
              <td>{{ item.contact_profile.full_name }} ({{ item.contact_profile.patient_id }})</td>
              <td class="text-right">{{ item.subtotal_amount }}</td>
              <td class="text-right">{{ item.total_discount }}</td>
              <td class="text-right">{{ item.total_amount }}</td>
              <td class="text-right">{{ item.incentive_amount }}</td>
              <td class="text-right">{{ item.referrer_discount_amount }}</td>
              <td class="text-right">{{ item.net_incentive }}</td>
              <td class="text-center">
                <input type="checkbox" @change="toggleSelection(item)" />
              </td>
            </tr>
            <tr v-if="tableItems.length > 0">
              <td colspan="3">Total</td>
              <td class="text-right">{{ totalData.total_invoice }}</td>
              <td class="text-right">{{ totalData.total_discount }}</td>
              <td class="text-right">{{ totalData.net_bill }}</td>
              <td class="text-right">{{ totalData.total_incentive }}</td>
              <td class="text-right">{{ totalData.total_referrer_discount }}</td>
              <td class="text-right">{{ totalData.net_incentive }}</td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="selectedItems.length > 0">
              <td colspan="3">Processed Amount</td>
              <td class="text-right">{{ selectedTotals.total_invoice }}</td>
              <td class="text-right">{{ selectedTotals.total_discount }}</td>
              <td class="text-right">{{ selectedTotals.net_bill }}</td>
              <td class="text-right">{{ selectedTotals.total_incentive }}</td>
              <td class="text-right">{{ selectedTotals.total_referrer_discount }}</td>
              <td class="text-right">{{ selectedTotals.net_incentive }}</td>
              <td class="text-right"></td>
            </tr>
            </tbody>
          </table>
          <p v-if="tableItems.length <= 0" class="text-center mt-5">No data</p>
        </div>
      </div>
    </div>
    </div>

    <div class="my-2 px-2">
    <div class="row mt-1" v-if="tableItems.length > 0">
      <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <input
            v-model="date"
            name="datetime"
            type="date"
            class="form-control"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <button :disabled="saveButtonLoader" @click="saveData()"
                class="btn btn-primary">
          Save
        </button>
      </div>
    </div>
    </div>

    <Loader v-if="loading"/>

  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {inject, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import { computed } from 'vue';
import handleInventory from "@/services/modules/inventory";
import handleHospital from "@/services/modules/hospital";
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import useDate from "@/services/utils/day";
import incentivePrinter from '@/services/utils/pdf/incentivePaymentDraft';
import handleCompany from "@/services/modules/company";

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const saveButtonLoader = ref(false)
const referrer = ref(null)
const date = ref(null)
const tableItems = ref([]);
const totalData = ref({});
const incentivePrintData = ref({});
const status = ref([]);
const params = ref({
  offset: 30,
});
let companyInfo = reactive({})

const token = computed(() => localStorage.getItem('token'));

const { fetchUnpaidIncentivePayment, storeIncentivePayment } = handleHospital()
const {fetchContactProfiles} = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchHome } = handleInventory()
const dateValue = useDate();
const { exportToPDF } = incentivePrinter();
const { fetchCompanyInfo } = handleCompany();
const selectedItems = ref([]);

const onClickToGo = () => {
  getUnpaidIncentivePaymentList();
}

function navigateToListPage() {
  router.push({name: `incentive-payment`, params: route.params, query: route.query});
}

const selectedTotals = computed(() => {
  return selectedItems.value.reduce((totals, item) => {
    totals.total_invoice += item.subtotal_amount;
    totals.total_discount += item.total_discount;
    totals.net_bill += item.total_amount;
    totals.total_incentive += item.incentive_amount;
    totals.total_referrer_discount += item.referrer_discount_amount;
    totals.net_incentive += item.net_incentive;
    return totals;
  }, {
    total_invoice: 0,
    total_discount: 0,
    net_bill: 0,
    total_incentive: 0,
    total_referrer_discount: 0,
    net_incentive: 0
  });
});

const toggleSelection = (item) => {
  const index = selectedItems.value.findIndex((selected) => selected.id === item.id);
  if (index === -1) {
    selectedItems.value.push(item);
  } else {
    selectedItems.value.splice(index, 1);
  }
};

const saveData = () => {
  if (selectedItems.value.length === 0) {
    showError("Please select at least one record.");
    return;
  }

  let incentivePaymentData = {
    company_id: route.params.companyId,
    contact_profile_id: referrer.value,
    date: date.value,
    status: "approved",
    item_details: selectedItems.value.map((item) => ({
      sale_master_id: item.id,
      total_invoice: item.subtotal_amount,
      total_discount: item.total_discount,
      net_bill: item.total_amount,
      total_incentive: item.incentive_amount,
      referrer_discount: item.referrer_discount_amount,
      net_incentive: item.net_incentive,
    })),
  };

    storeIncentivePayment(incentivePaymentData, getQuery())
        .then(res => {
          if (!res.status) {
            showError(res.message)
            return
          }
          showSuccess(res.message)
          navigateToListPage()
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          saveButtonLoader.value = false
     })
}

const getUnpaidIncentivePaymentList = async () => {
  const query = getQuery();
  loading.value = true
  tableItems.value = []

  await fetchUnpaidIncentivePayment(query).then(res => {
    if (!res.status){
      showError(res.message)
      return
    }

    tableItems.value = Array.isArray(res.data.data)
        ? res.data.data
        : Object.values(res.data.data);

    totalData.value = res.data.total;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const onClickToPrint = async () => {
  let query = `?company_id=${route.params.companyId}`;
  if (referrer.value) query += '&referrer_id=' + referrer.value;

  await fetchUnpaidIncentivePayment(query).then((res) => {
    if (!res.status) {
      showError(res.message)
      return;
    }
    incentivePrintData.value = res
  }).then(() => {
    exportToPDF(companyInfo,incentivePrintData.value, false)
  }).catch((err) => {
    console.log("Something went wrong");
  });
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

const getQuery = () => {
  let query = `?company_id=${route.params.companyId}`;
  if (referrer.value) query += '&referrer_id=' + referrer.value;
  return query;
}

onMounted(() => {
   date.value = dateValue.currentDate();
   setCompanyInfo()

})

</script>
